import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import '../../../custom-base.css';
import FTextInput from '../f-text-input/f-text-input';

export interface FHyperlinkProps extends FInputProps {
    name: string;
    value: string;
    id: string;
    color: string;
    hrefUrl: string;
    linkRegexp: string;
}

export default function FHyperlink(props: FHyperlinkProps): React.ReactElement {
    const baseInput = new FInput();
    var regexp = (typeof props.linkRegexp != 'undefined' && props.linkRegexp && props.linkRegexp.trim()) ? new RegExp(props.linkRegexp, 'gi') : /[T]+[S]+[K]+[0-9]+[^A-Z]/gi;
    let fieldValue = props.value;
    if (props.value != null) {
      const found = JSON.stringify(props.value).match(regexp);
      if (found != null && fieldValue != null) {
        const hrefUrl = props.hrefUrl;
        const hrefLink = '<a href="'+ hrefUrl + '" target="_blank" rel="noopener noreferrer">';
        for (let k = 0; k < found.length; k++) {
          if (fieldValue != null) {
            fieldValue = fieldValue.replace(found[k], hrefLink + found[k] + '</a>');
            fieldValue = '<span> <link-text style="white-space: nowrap;" class="content-input-link-text">' + fieldValue + '</link-text> </span>';
          }
        }
      }
    }
    return (
        <div className={baseInput.getInputWrapperClasses(props)}>
            <div dangerouslySetInnerHTML={{ __html: fieldValue}}></div>
        </div>
    );
}
