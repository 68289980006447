import * as React from 'react';

// Fresche
import FGridCell from '../f-grid-cell/f-grid-cell';
import FActionButton from '../../buttons/f-action-button/f-action-button';
import FDateTimeInput from '../../layout/f-date-time-input/f-date-time-input';
import FTextInput from '../../layout/f-text-input/f-text-input';
import FSelectInput from '../../layout/f-select-input/f-select-input';

// Prime React
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

// Style
import './f-grid.css';

/**
 *
 */
export interface FGridProps {
  /**
   * model containing all the grid values
   */
  model: any;

  /**
   * Screen Definition
   */
  screenDef: any;

  /**
   * Errors associated to filter
   */
  filterErrors?: any;

  /**
   *
   */
  selectionMode?: string;

  /**
   *
   */
  conditions?: any;

  dateFormat?: string;
  timeSeparator?: string;
  showSeconds?: boolean;

  /**
   * If the action buttons are showed as a menu or not
   */
  actionsAsMenu?: boolean;

  /**
   * triggered when a part of the model has changed
   */
  onModelChange: (model: object) => any;

  /**
   * triggered when grid pages changes
   */
  onPageChange?: (e?: any) => any;

  /**
   * triggerd when a field is focused
   */
  onTableFieldFocus?: (fieldName: string, shortFieldName: string, rowNumber: number) => void;

  /**
   * triggered when user click on action button
   */
  onActionButton?: (command: string) => any;

  /**
   * If you want override the body of the grid
   */
  body?: (i: any) => React.ReactElement;

  autoFocus?: boolean;
  
  /**
   * hyperlink to be displayed
  */
  hrefUrl: string;
  /**
   * link regex pattern
   */
  linkRegexp: string;
}

/**
 *
 */
export interface FGridState {
  model: any;
  selectedRows: any;
}

/**
 *
 */
export default class FGrid extends React.Component<FGridProps, FGridState> {
  // Class references
  public tableElement: any;
  public table = React.createRef();
  public actionMenu: any;

  public autofocus: boolean;

  private dateFormat: string;
  private timeSeparator: string;
  private showSeconds: boolean;

  public gridLength: number;
  public hasFilters: boolean; // if the grid has any filters
  public selectionMode: string;
  public isMenuOpen: boolean;

  private originalData: any;
  private totalRecords: number;
  private numRows: number; // numrows attribute of the grid define number of row displayed per page
  private disabledSelections: object[]; // has indexes of records with disabled selections
  private firstPage: number; // id of the first page used by primeng table
  private currentPageNumber: number; // current page number position start at 0

  private gridDefinition: any;
  private selectedRows: any;
  private actionItems: any[] = [];
  private hrefUrl: string;
  private linkRegexp: string;
  /**
   *
   */
  constructor(props: FGridProps) {
    super(props);

    this.state = {
      model: this.props.model,
      selectedRows: (this.props.selectionMode === 'single' || this.selectionMode === 'noradio')? {} : [],
    };

    // Make a value copy of the original model
    this.originalData = JSON.parse(JSON.stringify(this.props.model));
    this.totalRecords = 1000;
    this.numRows = 10;
    this.disabledSelections = [];
    this.hasFilters = false;
    this.firstPage = 0;
    this.currentPageNumber = 0;

    this.dateFormat = this.props.dateFormat ? this.props.dateFormat : 'yy-mm-dd';
    this.timeSeparator = this.props.timeSeparator ? this.props.timeSeparator : ':';
    this.showSeconds = this.props.showSeconds ? this.props.showSeconds : true;

    this.autofocus = true;
    this.gridDefinition = this.props.screenDef.gridDefinition;

    this.hrefUrl = (typeof props.screenDef.hrefUrl != 'undefined' && this.props.screenDef.hrefUrl && this.props.screenDef.hrefUrl.trim()) ? this.props.screenDef.hrefUrl : this.props.hrefUrl;
    this.linkRegexp = (typeof props.screenDef.linkRegexp != 'undefined' && this.props.screenDef.linkRegexp && this.props.screenDef.linkRegexp.trim()) ? this.props.screenDef.linkRegexp : this.props.linkRegexp;

     // to have access to "this"
    this.cellBodyTemplate = this.cellBodyTemplate.bind(this);
    this.actionMenuBodyTemplate = this.actionMenuBodyTemplate.bind(this);
    this.getCellFilter = this.getCellFilter.bind(this);
    this.getPage = this.getPage.bind(this);
    this.movePage = this.movePage.bind(this);

    this.initialize();
  }

  /**
   *
   */
  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  /**
   *
   */
  componentDidUpdate(): void {
    let needRefreshState = false;

    if (JSON.stringify(this.gridDefinition) !== JSON.stringify(this.props.screenDef.gridDefinition)) {
      needRefreshState = true;
    }

    // Because we have a dynamic nested object, we compare each node valud
    if (JSON.stringify(this.state.model.fields) !== JSON.stringify(this.props.model.fields)) {
      needRefreshState = true;
    }

    if (this.state.model.subfile && this.props.model.subfile) {
      for (let index = 0; index < this.props.model.subfile.length; index++) {
        const propsFields = JSON.stringify(this.props.model.subfile[index]);
        const stateFields = this.state.model.subfile[index] ? JSON.stringify(this.state.model.subfile[index]) : '';

        if (propsFields !== stateFields) {
          needRefreshState = true;
        }
      }

      if (this.state.model.subfile.length !== this.props.model.subfile.length) {
        needRefreshState = true;
      }
    }

    if (needRefreshState) {
      this.gridDefinition = this.props.screenDef.gridDefinition;
      this.setState({ model: this.props.model });
      this.initialize();
    }
  }

  /**
   *
   */
  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  /**
   * Initialize the compoment
   */
  initialize(): void {
    this.generateTableData();
    this.checkForDisabledSelections();
    this.setupGrid();
  }

  /**
   * generate table data
   */
  generateTableData(): void {
    if (this.props.model !== undefined) {
      if (this.props.model['subfile'] !== null && this.props.model['subfile'] !== undefined) {
        this.selectedRows = [];
        for (const record of this.props.model['subfile']) {
          if (record['recordSelected'] === 'Y') {
            this.selectedRows.push(record);
          }
        }

        // Copy by JSON strignify to make sure all object are copy
        this.originalData = JSON.parse(JSON.stringify(this.props.model));

        // Assign totalRecords if isLastPage == true to total till now and defaults at 10000
        //this.totalRecords = this.props.model['isLastPage'] ?
        //  (this.props.model['subfilePageNumber'] * this.props.model['subfilePageSize']) + this.props.model['subfile'].length : this.props.model['totalRecords'];
          this.totalRecords = this.props.model['isLastPage'] ?
          (this.props.model['subfilePageNumber'] * this.props.model['subfilePageSize']) + this.props.model['subfile'].length : 10000;

          // Assign rowSize from the model
        this.numRows = this.props.model['subfilePageSize'];

        let currentItemNumber = 0;
        if (this.props.model['subfile'] && this.props.model['subfile'].length > 0) {
          currentItemNumber = Math.floor(this.props.model['subfilePageNumber'] * this.props.model['subfilePageSize']);
        } else {
          this.totalRecords = 0;
        }
        this.currentPageNumber = this.props.model['subfilePageNumber'];
        this.firstPage = currentItemNumber;
      }
    }
  }

  /**
   * Checks the grid records for any disabled selections
   */
  checkForDisabledSelections(): void {
    this.disabledSelections = [];
    if (this.props.model['subfile']) {
      this.props.model['subfile'].forEach((record: object) => {
        if (record['fieldConditions'] && record['fieldConditions'].length > 0) {
          record['fieldConditions'].forEach((condition: object) => {
            if (condition['RCD_selected']) {
              const disabledRecordIndex: any = this.props.model['subfile'].indexOf(record)
                + Math.floor(this.props.model['subfilePageNumber'] * this.props.model['subfilePageSize']);

              this.disabledSelections.push(disabledRecordIndex);
            }
          });
        }
      });
    }
  }

  /**
   * Set some properties
   */
  setupGrid(): void {
    this.hasFilters = false;
    if (this.gridDefinition) {
      if (this.gridDefinition.columns) {
        let totalLength = 0;
        this.gridDefinition.columns.forEach((column: any) => {
          if (column[0].size) {
            totalLength += column[0].size;
          }

          column.forEach((cell: any) => {
            // If cell has filters
            if (cell.filters && cell.filters.length && !this.hiddenColumn(column[0].field)) {
              let cellHiddenFilters = 0;
              cell.filters.forEach((filter: any) => {
                if (this.hiddenFilter(filter)) {
                  cellHiddenFilters++;
                }
              });
              if (cellHiddenFilters < cell.filters.length) {
                this.hasFilters = true;
              }
            }
            // If cell has a label
            if (cell.label) {
              cell.label = cell.label;
            }
            // If cell has options
            if (cell.options && cell.options.length) {
              // Loop every cell
              cell.options.forEach((option: any, index: number) => {
                // Translate every label
                cell.options[index].label = option.label;
              });
            }
          });
        });
        this.gridLength = totalLength;
      }
      // Transform grid definition actions to fit Prime's Menu items
      if (this.gridDefinition.actions) {
        this.actionItems = [];
        this.gridDefinition.actions.forEach((item: any, index: number): void => {
          // Clone action from grid definition
          const clonedItem = JSON.parse(JSON.stringify(item));
          // Keep a trace of the action's command value
          const cmd = this.gridDefinition.actions[index].command;
          // Set the command attribute as a function (called when Prime item menu clicked)
          clonedItem.command = (): void => {
            this.onActionButtonClick(cmd);
          };
          // Add item to the list of items that's used by the Prime menu
          this.actionItems.push(clonedItem);
        });
      }
      this.selectionMode = this.props.selectionMode ? this.props.selectionMode : this.gridDefinition.selectionMode;
    }
  }

  // ====================================
  //  GRID PAGINATION
  // ====================================

  /**
   * Keydown listener
   */
  handleKeyDown = (event: KeyboardEvent): void => {
    switch (event.key) {
      case 'PageUp':
        event.preventDefault();
        this.movePage('previous');
        break;
      case 'PageDown':
        event.preventDefault();
        this.movePage('next');
        break;
      default:
        break;
    }
  }

  /**
   * compare next page and current to determine paginaiton direction then set command to model...
   */
  getPage(event: any): void {
    const nextPage = (event.first / event.rows);
    if (this.currentPageNumber > nextPage && this.props.model['subfile'].length > 0) {
      this.movePage('previous');
    } else if (this.currentPageNumber < nextPage && this.props.model['subfile'].length > 0) {
      this.movePage('next');
    }
  }

  /**
   *
   */
  movePage(direction: string): void {
    if (((direction === 'next' && !this.props.model['isLastPage']) ||
      (direction === 'previous' && this.currentPageNumber !== 0)) && this.props.onPageChange !== undefined) {
      this.props.onPageChange(direction);
    }
  }

  // ====================================
  //  DATA HANDLING FUNCTIONS
  // ====================================

  /**
   * Checks in the conditions object if a column should be hidden.
   * @param fieldName Name of the column to check for
   */
  hiddenColumn(fieldName: string): boolean {
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid[fieldName]) {
      // console.log(`**** GRID - conditions: ${JSON.stringify(this.props.conditions)}`);
      const conditions = this.props.conditions.grid[fieldName];

      let isHiddenColumn = conditions.hidden !== undefined ? conditions.hidden : false;

      if (isHiddenColumn === false && conditions.rows) {
        // If not hidden by its initialDisplay then we look inside each "rows" for a hidden property
        for (let i = 0; ; i++) {
          if (conditions.rows[i] === undefined) {
            break;
          }

          if (conditions.rows[i].hidden !== undefined) {
            isHiddenColumn = conditions.rows[i].hidden;
            if (conditions.rows[i].hidden === false) {
              isHiddenColumn = false;
              break; // Exit for loop and not hide the column
            }
          }
        }
      }

      return isHiddenColumn;
    }
    return false;
  }

  /**
   *
   */
  getColumnWidth(col: any): any {
    if (col && this.gridLength) {
      const lengthValue = (col.size !== undefined) ? col.size : (col.displayFormat && col.displayFormat.textLength ? col.displayFormat.textLength : (col.textLength ? col.textLength : null));
      return { minWidth: `${lengthValue}rem` };
      //return { minWidth: '55px', width: `${((lengthValue / this.gridLength) * 100)}%` };
    }
  }

  /**
   * Checks in the conditions object if a filter should be hidden.
   * @param filter the filter object
   */
  hiddenFilter(filter: any): boolean {
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid.filters) {
      const cellConditions = this.props.conditions.grid.filters[filter.field];
      if (cellConditions) {
        return cellConditions.hidden !== undefined ? cellConditions.hidden : false;
      }
    }
    return false;
  }

  /**
   * Checks in the conditions object if a action should be hidden.
   * @param action the axction object
   */
  hiddenActionButton(action: any): boolean {
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid.actions) {
      const actionConditions = this.props.conditions.grid.actions[action.key];
      if (actionConditions) {
        return actionConditions.hidden !== undefined ? actionConditions.hidden : false;
      }
    }

    return false;
  }

  /**
   *
   */
  onSelectionChange(selection: any): void {
    this.selectedRows = selection;

    const cloneModel = { ...this.state.model };

    // unselect the previous selection
    cloneModel['subfile'].forEach((element: any): any => {
      element['recordSelected'] = 'N';
      element['recordDataChanged'] = 'N';
    });

    const singleSelectionMode = !Array.isArray(selection);

    // Because PrimeReact declare selection has an array if multiple selection is set
    if (singleSelectionMode) {
      if (selection) {
        selection['recordDataChanged'] = selection['recordSelected'] = 'Y';
      }
    } else {
      // select the new selection in the model
      selection.forEach((element: any): any => {
        element['recordSelected'] = 'Y';
        element['recordDataChanged'] = 'Y';
      });
    }

    this.props.onModelChange(cloneModel);
  }
    /**
   * 
   */
     onRowSelect(): void {
      if (this.gridDefinition.actionOnRowSelect && this.gridDefinition.actionOnRowSelect.length >= 0) {
        // If actionOnRowSelect is present and the value is not empty
        const actionPosition = this.gridDefinition.actions.map((action: any, _index: any): any => { return action.key }).indexOf(this.gridDefinition.actionOnRowSelect);
        const actionOnRowSelect = this.gridDefinition.actions[actionPosition];
        this.onActionButtonClick(actionOnRowSelect.command);
      }
    }
   
  /**
   *
   */
  onFilterValueChange(newValue: any, field: string): any {
    const cloneModel = { ...this.state.model };
    cloneModel['fields'][field] = newValue;
    this.props.onModelChange(cloneModel);

  }

  /**
   * manage a change of value in a grid cell
   */
  onCellValueChange(newValue: any, field: string, rowData: any, rowIndex: number): any {
    const cloneModel = { ...this.state.model };
    cloneModel['subfile'][rowIndex]['fields'][field] = newValue;

    if (this.selectionMode === 'single' || this.selectionMode === 'noradio') {
      this.onSelectionChange(rowData);
    } else {
      const isOriginalValue = this.originalData['subfile'][rowIndex]['fields'][field] === newValue;

      let selectionIndex = -1;
      let selectionsCopy: any[] = [];

      if (this.selectedRows) {
        selectionIndex = this.selectedRows.indexOf(rowData);
        selectionsCopy = this.selectedRows.slice();
      }

      if (selectionIndex === -1) {
        selectionsCopy.push(rowData);
        this.onSelectionChange(selectionsCopy);
      } else {
        if (isOriginalValue) {
          selectionsCopy.splice(selectionIndex ? selectionIndex : 0, 1);
          this.onSelectionChange(selectionsCopy);
        } else {
          cloneModel['subfile'][rowIndex]['recordSelected'] = 'Y';
          cloneModel['subfile'][rowIndex]['recordDataChanged'] = 'Y';
          this.props.onModelChange(cloneModel);
        }
      }
    }
  }

  /**
   * Navigates through the grid based on keydown events.
   * @param event keydown event
   */
  gridNavigation(event: any): void {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      event.preventDefault();
      // Get the focused cell
      const focusedElement = document.activeElement;
      // Get the cells in the same column
      const sameColCells = this.getColumnCells();

      if (sameColCells.length) {
        let nextCellIndex = -1;
        sameColCells.forEach((cell: any, cellIndex: number): any => {
          const element = cell.htmlElement.element.nativeElement;
          if (element === focusedElement) {
            nextCellIndex = (event.code === 'ArrowDown') ? cellIndex + 1 : cellIndex - 1;
          }
        });
        if (
          (event.code === 'ArrowUp' && nextCellIndex > -1 && nextCellIndex >= 0) ||
          (event.code === 'ArrowDown' && nextCellIndex > -1 && nextCellIndex < sameColCells.length)
        ) {
          const elementToFocus = sameColCells[nextCellIndex].htmlElement.element.nativeElement;
          elementToFocus.focus();
        }
      }
    }
  }

  /**
   * Returns all the focusable cells that are in the same column as
   * the current focused cell.
   */
  getColumnCells(): any[] {
    /*
    // Get the focused cell
    const focusedElement = document.activeElement;
    // Get the index of the column containing the focused cell
    const columnIndex = focusedElement.closest('td').cellIndex;
    if (focusedElement && columnIndex && this.gridCells) {
      // Get all the cells of the grid
      const sameColCells = this.gridCells.toArray().filter((cell: any) => {
        // Skip dropdowns
        if (!(cell.htmlElement instanceof FSelectInputComponent)) {
          const input = cell.htmlElement;
          // Skip cells that are protected or in readonly mode
          if (!input.protect && !input.readonly) {
            const element = input.element.nativeElement;
            return element && element.closest('td').cellIndex === columnIndex;
          }
        }
      });
      return sameColCells;
    }*/
    return [];
  }

  /**
   * Return true if type is numeric or date
   */
  isRightAlign(type: string): boolean {
    const rightAlignTypes = ['number', 'pnumber', 'pinteger', 'integer', 'currency', 'datetime', 'date', 'time'];
    return rightAlignTypes.indexOf(type) !== -1;
  }

  /**
   * Checks if the selector for a given row is hidden.
   * @param rowIndex index of the grid row.
   */
  // TODO: Bind this code in the html to hide selector
  hiddenSelector(rowIndex: any): boolean {
    let hidden = false;
    if (this.disabledSelections.indexOf(rowIndex) > -1) {
      hidden = true;
    }

    const selectionConditions = this.props.conditions.grid.gridSelection;
    if (selectionConditions) {
      if (selectionConditions.hidden || selectionConditions.protect || selectionConditions.readonly) {
        hidden = true;
      }
      if (selectionConditions.rows && selectionConditions.rows[rowIndex]) {
        const specificCondition = selectionConditions.rows[rowIndex];
        if (specificCondition.hidden || specificCondition.protect || specificCondition.readonly) {
          hidden = true;
        }
      }
    }
    return hidden;
  }

  /**
   * Returns a columns initial display as an object of conditions
   * usable by FGridCell
   */
  getCellConditions(col: any, rowIndex: number): any {
    const field = col.field;
    if (this.props.conditions && this.props.conditions.grid && this.props.conditions.grid[field]) {
      const conditions = this.props.conditions.grid[field];
      if (conditions.rows && conditions.rows[rowIndex] && Object.keys(conditions.rows[rowIndex.toString()]).length) {
        return conditions.rows[rowIndex];
      } else {
        return conditions;
      }
    }
    return {};
  }

  /**
   * Called when an action button is clicked.
   */
  onActionButtonClick(command: string): void {
    if (this.props.onActionButton) {
      this.props.onActionButton(command);
    }
  }

  /**
   * Return a string of all classes in the addClass array property
   */
  getAddClass(classes: string[]): string {
    let result = '';

    if (classes) {
      result = classes.join(' ');
    }

    return result;
  }

  /**
   *
   */
  getFieldErrorMessage(errorMsgs: any, fieldName: string): string {
    let errorMessages = '';
    if (errorMsgs) {
      errorMsgs.forEach((message: any) => {
        if (message['fieldsName'].indexOf(fieldName) !== -1) {
          errorMessages = message['messageText'];
        }
      });
    }
    return errorMessages;
  }

  /**
   * Called when a row is clicked. Adds/removes row from the selection.
   * @param ev click event
   * @param rowIndex index of the clicked row
   */
  selectRow(ev: any, rowIndex: any): void {
    // Get selection mode
    const mode = this.selectionMode;
    if (mode !== 'none' && ev && ev.target && ev.target.classList !== undefined) {
      const classes = ev.target.classList;
      // If selection box is cliked OR input that is protected
      if (classes.contains('selection-box') || (classes.contains('content-input') && classes.contains('protect'))) {
        this.toggleFromSelection(rowIndex);
      }
      if (classes.contains('content-input')) { 
      //&& (ev.target.parentNode && ev.target.parentNode.parentNode 
      //  && ev.target.parentNode.parentNode.parentNode 
      //  && ev.target.parentNode.parentNode.parentNode.classList 
      //  && ev.target.parentNode.parentNode.parentNode.classList.contains('action-on-row-select'))) {
      //if (classes.contains('p-inputtext') && (ev.target.parentNode && ev.target.parentNode.parentNode && ev.target.parentNode.parentNode.classList.contains('underline'))) {
        //this.toggleFromSelection(rowIndex);

        // Only on single selection
        if (this.selectionMode === 'single' || this.selectionMode === 'noradio') {
          this.onRowSelect();
        }
      }
    }
  }

  getRowByIndex(rowIndex: number): any {
    // Clone the model
    const cloneModel = { ...this.state.model };
    if (cloneModel['subfile'] && cloneModel['subfile'][rowIndex]) {
      // Get the clicked row from the model
      return cloneModel['subfile'][rowIndex];
    }
  }

  toggleFromSelection(rowIndex: number): void {
    if (!this.isInSelection(rowIndex)) {
      this.addToSelection(rowIndex);
    } else {
      this.removeFromSelection(rowIndex);
    }
  }

  /**
   * Removes a row to the grid selection.
   * @param rowIndex index of the row to add to the selection.
   */
  removeFromSelection(rowIndex: number): void {
    if (this.selectionMode === 'single' || this.selectionMode === 'noradio') {
      this.selectedRows = undefined;
    } else if (this.selectionMode === 'multiple') {
      const index = this.selectedRows.indexOf(this.getRowByIndex(rowIndex));
      this.selectedRows.splice(index, 1);
    }
    // Call selection management method
    this.onSelectionChange(this.selectedRows);
  }

  /**
   * Adds a row to the grid selection.
   * @param rowIndex index of the row to add to the selection.
   */
  addToSelection(rowIndex: number): void {
    if (this.selectionMode === 'single' || this.selectionMode === 'noradio') {
      this.selectedRows = this.getRowByIndex(rowIndex);
    } else if (this.selectionMode === 'multiple') {
      this.selectedRows.push(this.getRowByIndex(rowIndex));
    }
    // Call selection management method
    this.onSelectionChange(this.selectedRows);
  }

  /**
   * Check if a grid row is in the current selection.
   * @param rowIndex index of the row to add to the selection.
   */
  isInSelection(rowIndex: number): boolean {
    const row = this.getRowByIndex(rowIndex);
    if (this.selectionMode === 'single' || this.selectionMode === 'noradio') {
      return JSON.stringify(this.selectedRows) === JSON.stringify(row);
    } else if (this.selectionMode === 'multiple') {
      // Get the index of the cliked row in the current selection
      const index = this.selectedRows.indexOf(row);
      return index >= 0;
    }
    return false;
  }

  /**
   * Returns the options with translated labels
   */
  getDropdownValues(options: any, field: string): any {
    // First if dropdownOptions are in the model for this field
    if (this.props.model.dropdownOptions && this.props.model.dropdownOptions[field]) {
      // Clear options (if they are declared in screen definition)
      options = [];

      // Add to options
      this.props.model.dropdownOptions[field].forEach((option: any, _index: number): void => {
        options.push({ value: option.code, label: option.description });
      });
    }

    return options;
  }

  /**
   * A function to implement custom export. Need to return string value.
   * event.data: Field data.
   * event.rows: Column field.
   */
   gridExport(_event: any): any {
  }

  /**
   *
   */
  exportToCSV(selectionOnly: boolean): any {
    // this.dt.exportCSV({ selectionOnly }); // Export on grid doesn't work

    // Return the data of the subfile into a simple array.  Check if user wants only the selected records.
    const selectedData = this.props.model['subfile'].filter((data: any) => data.recordSelected === 'Y' || selectionOnly === false).map((obj: any) => obj.fields);

    //this.exportToCsvFile('download.csv', selectedData);
    const readonlyColumns = this.gridDefinition.columns.filter((col: any) => this.hiddenColumn(col[0].field)).map((obj: any) => obj[0].field);

    const dataToExport: any[] = [];

    // 2) Remove readonly from each row
    selectedData.forEach((row: any) => {
      readonlyColumns.forEach((roCol: string) => {
        console.log(roCol);
        delete row[roCol];
      });
      dataToExport.push(row);
    });

    this.exportToCsvFile('download.csv', dataToExport);

  }

    /**
   * return index of the defined field in subfileFields property.  Index is important because we can have 2 or more fields in a cell
   */
     getRowDataFieldIndex(field: string): number {
      if (this.props.model !== undefined) {
        if (this.props.model['subfile'] !== null && this.props.model['subfile'] !== undefined) {
          return this.props.model['subfileFields'].filter((data: any) => data.field === field).map((obj: any) => obj.index);
        }
      }
  
      return 0;
    }
  
  /**
   *
   */
  exportToCsvFile(filename: string, rows: object[]): void {
    if (!rows || !rows.length) {
      return;
    }

    const separator = ',';
    const keys = Object.keys(rows[0]);
    const titles: any[] = [];

    keys.forEach((k: any) => {
      //const title = this.gridDefinition.columns.filter((col: any) => col[0].field === k).map((obj: any) => obj[0].label !== '' ? obj[0].label : obj[0].header1);
        const title = this.gridDefinition.columns.filter((col: any) => col[0].field === k).map((obj: any) => obj[0].label !== '' ? obj[0].label : obj[0].header1);
      titles.push(title);
    });

    const csvContent =
      titles.join(separator) +
      '\n' +
      rows.map((row: any) => {
        return keys.map((k: any) => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');

    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  // ====================================
  //  GRID RENDERING
  // ====================================

  /**
   * Render the inputs for the filter cell
   */
  getCellFilter(filter: any, index: any): any {
    if (this.props.model['fields'][filter.field] !== undefined) {
      if (filter.type === 'string' || filter.type === 'long' || filter.type === 'number') {
        const keyFilter = (filter.type === 'number') ? 'num' : '';
        const textAlign = (filter.type === 'number') ? 'right' : 'left';
        return (
          <React.Fragment key={'filter' + index}>
            {filter.label && filter.label !== '' && (<label className="filter-label">{filter.label}</label>)}

            <FTextInput
              className={`${this.getAddClass(filter.addClass)}`}
              id={filter.field}
              readonly={filter.condition ? filter.condition['readonly'] : false}
              value={this.props.model['fields'][filter.field]}
              maxlength={filter.displayFormat && filter.displayFormat.textLength && filter.displayFormat.textLength ? filter.displayFormat.textLength : -1}
              required={filter.required}

              displayFormat={filter.displayFormat}
              promptable={filter.lookupFlag}
              suffix={filter.options ? filter.options.suffix : null}

              onValueChange={(newValue: any): void => {
                if (filter.displayFormat && filter.displayFormat.textCase && filter.displayFormat.textCase.toLowerCase() === 'uppercase') {
                  newValue = newValue.toUpperCase();
                }
                this.onFilterValueChange(newValue, filter.field);
              }}
              // onFocus={(e: any): void => this.onFocus(e)}

              color={filter.condition ? filter.condition['color'] : ''}
              highintensity={filter.condition ? filter.condition['highintensity'] : ''}
              protect={filter.condition ? filter.condition['protect'] : ''}
              reverseimage={filter.condition ? filter.condition['reverseimage'] : ''}
              underline={filter.condition ? filter.condition['underline'] : ''}
              hidden={filter.condition ? filter.condition['hidden'] : ''}

              // error={this.getFieldErrorMessage(this.props.model['messageDTO'], filter.field)}
              error={this.props.filterErrors && this.props.filterErrors[filter.field] ? this.props.filterErrors[filter.field] : ''}
            // style={{ textAlign }}
            />
          </React.Fragment>
        );
      } else if (filter.type === 'dropdown') {
        return (
          <React.Fragment key={'filter' + index}>
            {filter.label && filter.label !== '' && (<label className="filter-label">{filter.label}</label>)}
            <FSelectInput
              className={`${this.getAddClass(filter.addClass)}`}
              key={'DropDown' + index}
              id={filter.field}
              name={filter.field}
              placeholder={filter.placeholder}
              value={this.props.model['fields'][filter.field]}
              values={this.getDropdownValues(filter.values, filter.field)}
              label={filter.labelLocation === 'above' ? filter.header1 : filter.label}
              maxlength={filter.displayFormat && filter.displayFormat.textLength ? filter.displayFormat.textLength : filter.textLength}
              required={filter.required}
              displayFormat={filter.displayFormat}
              onValueChange={(newValue: any): void => {
                this.onFilterValueChange(newValue, filter.field);
              }}
              promptable={filter.lookupFlag}
              customValue={filter.customValue}
              suffix={filter.options ? filter.options.suffix : null}
              labelOnTop={filter.labelLocation === 'above'}
              color={filter.condition ? filter.condition['color'] : ''}
              readonly={filter.condition ? filter.condition['readonly'] : ''}
              highintensity={filter.condition ? filter.condition['highintensity'] : ''}
              protect={filter.condition ? filter.condition['protect'] : ''}
              reverseimage={filter.condition ? filter.condition['reverseimage'] : ''}
              underline={filter.condition ? filter.condition['underline'] : ''}
              hidden={filter.condition ? filter.condition['hidden'] : ''}

              // error={this.getFieldErrorMessage(this.props.model['messageDTO'], filter.field)}
              error={this.props.filterErrors && this.props.filterErrors[filter.field] ? this.props.filterErrors[filter.field] : ''}
            />
          </React.Fragment>
        );
      } else if (filter.type === 'datetime' || filter.type === 'date' || filter.type === 'time') {
        return (
          <React.Fragment key={'filter' + index}>
            {filter.label && filter.label !== '' && (<label className="filter-label">{filter.label}</label>)}
            <FDateTimeInput
              className={`${this.getAddClass(filter.addClass)}`}
              id={filter.field}
              dateFormat={this.dateFormat}
              timeSeparator={this.timeSeparator}
              showSeconds={this.showSeconds}
              type={filter.type}
              readonly={filter.condition ? filter.condition['readonly'] : false}
              value={this.props.model['fields'][filter.field] !== '0001-01-01' ? this.props.model['fields'][filter.field] : null}
              promptable={false}
              onValueChange={(newValue: any): void => { this.onFilterValueChange(newValue, filter.field); }}

              color={filter.condition ? filter.condition['color'] : ''}
              highintensity={filter.condition ? filter.condition['highintensity'] : ''}
              protect={filter.condition ? filter.condition['protect'] : ''}
              reverseimage={filter.condition ? filter.condition['reverseimage'] : ''}
              underline={filter.condition ? filter.condition['underline'] : ''}
              hidden={filter.condition ? filter.condition['hidden'] : ''}

              // error={this.getFieldErrorMessage(this.props.model['messageDTO'], filter.field)}
              error={this.props.filterErrors && this.props.filterErrors[filter.field] ? this.props.filterErrors[filter.field] : ''}
            />
          </React.Fragment>
        );
      }
    }
    // return (<span key={'filter' + index} />);
    return (<React.Fragment key={'filter' + index} />);
  }

  /**
   * Renders the filters of a single cell
   * @param cell screen definition section representing a grid cell
   */
  getCellFilters(cell: any, index: any): any {
    if (cell.filters && cell.filters.length) {
      return (
        <React.Fragment key={index}>
          {
            cell.filters.map((filter: any, i: any): any => {
              return this.getCellFilter(filter, i);
            })
          }
        </React.Fragment>
      );
    } else {
      return (<React.Fragment key={index} />);
    }
  }

  /**
   * Renders the headers of the grid
   */
  getGridDataTableHeader(): any {
    if (this.gridDefinition.actions) {
      return (
        <React.Fragment>
          <div className="action-buttons">
            <div>
              <div className="actions">
                {
                  this.gridDefinition.actions.map((action: any, index: any): any => {
                    return this.getActionButtons(action, index);
                  })
                }
              </div>
              <Tooltip target=".export-buttons>button" position="bottom" />
              {(this.props.model.subfileHasAllData || this.props.screenDef.gridDefinition.exportable) && (
                <div className="export-buttons">
                  <Button type="button" icon="pi pi-file" onClick={(): any => this.exportToCSV(false)} className="p-mr-2" data-pr-tooltip="Export" />
                  <Button type="button" icon="pi pi-file-excel" onClick={(): any => this.exportToCSV(true)} className="p-button-info p-ml-auto" data-pr-tooltip="Export Selection Only" />
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  getActionButtons(action: any, index: any): any {
    if (!this.hiddenActionButton(action)) {
      const actionOnRowSelect = this.props.screenDef.gridDefinition.actionOnRowSelect && this.props.screenDef.gridDefinition.actionOnRowSelect === action.key;
      return (
        <FActionButton className={`${actionOnRowSelect ? 'actionOnRowSelect' : ''} ${this.getAddClass(action.addClass)}`} key={'FActionButton' + index} legacy={true} tooltip={action.label} icon={action.icon} title={action.label} disabled={action.disable} action={(): any => this.onActionButtonClick(action.command)} />
      );
      //return (
      //  <FActionButton className={`${this.getAddClass(action.addClass)}`} key={'FActionButton' + index} legacy={true} tooltip={action.label} icon={action.icon} title={action.label} disabled={action.disable} action={(): any => this.onActionButtonClick(action.command)} />
      //);
    }
  }

  /**
   * Generate the content for a cell
   */
  cellBodyTemplate(rowData: any, props: any): any {
    const colIndex = parseInt(props.columnKey, 10);
    const colInformation = this.gridDefinition.columns[colIndex][0];
    //const cellFieldIndex = this.props.model['subfileFields'].filter((data: any) => data.field === props.field).map((obj: any) => obj.index)

    return (
      <div className="selection-box" onClick={(event: any): any => this.selectRow(event, props.rowIndex)} >
        <FGridCell
          singleSelectionMode={this.selectionMode === 'single' || this.selectionMode === 'noradio'}
          definition={colInformation}
          row={props.rowIndex}
          data={rowData['fields'][props.field]}
          dropdownOptions={this.props.model['dropdownOptions']}
          fieldName={props.field}
          maxlength={colInformation.displayFormat && colInformation.displayFormat.textLength ? colInformation.displayFormat.textLength : (colInformation.textLength ? colInformation.textLength : undefined)}
          dateFormat={this.dateFormat}
          // displayValueOnly={rowData.displayValueOnly}
          timeSeparator={this.timeSeparator}
          showSeconds={this.showSeconds}
          errorMessage={this.getFieldErrorMessage(rowData['messageDTO'], props.field)}
          condition={this.getCellConditions(colInformation, props.rowIndex)}
          dataChange={(newValue: any): any => this.onCellValueChange(newValue, props.field, rowData, props.rowIndex)}
          onFocus={(): void => { if (this.props.onTableFieldFocus) { this.props.onTableFieldFocus(colInformation.field, colInformation.fieldId, props.rowIndex); } } } 
          //hrefUrl={colInformation.hrefUrl} 
          hrefUrl={this.props.screenDef.hrefUrl} 
          linkRegexp={this.props.screenDef.linkRegexp} 
        />
      </div>
    );
  }

  /**
   *
   */
  getColumnHeader(col: any): any {
    return (
      <React.Fragment>
        {this.hasFilters && (
          <React.Fragment>
            {
              col.filters && col.filters.length > 0 && (
                <div className="filter">
                  {this.getCellFilters(col, 0)}
                </div>
              )
            }
            {(!col.filters || (col.filters && col.filters.length === 0)) && (
              <div className="filter empty">
                <span />
              </div>
            )}
          </React.Fragment>
        )}
        {!this.hiddenColumn(col.field) && (
          <React.Fragment>
            <div className="header-title" style={{ textAlign: (this.isRightAlign(col.type)) ? 'right' : 'left' }}>
              {col.header1}
            </div>
            {col.header2 && col.header2 !== '' && (
              <div className="header-title" style={{ textAlign: (this.isRightAlign(col.type)) ? 'right' : 'left' }}>
                {col.header2}
              </div>
            )}
            {col.header3 && col.header3 !== '' && (
              <div className="header-title" style={{ textAlign: (this.isRightAlign(col.type)) ? 'right' : 'left' }}>
                {col.header3}
              </div>
            )}
          </React.Fragment>
        )
        }

      </React.Fragment>
    );
  }

  /**
   * Return classname for the row
   */
  rowClass(rowData: any): any {
    let rowClass = { 'row-error': false };

    if (rowData.messageDTO) {
      rowData.messageDTO.forEach((error: any): any => {
        // if messageDTO doesn't have a field then it's for the entire row
        if (error.fieldsName.length === 0) {
          rowClass = { 'row-error': true };
          return;
        }
      });
    }
    return rowClass;
  }

  /**
   *
   */
   getSortable(col: any): boolean {
    if (col) {
      // if the subfile has all the data and the column is sortable
      if ((this.props.model.subfileHasAllData === true || this.props.screenDef.gridDefinition.exportable) && col.sortable !== false) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   */
  getExportable(col: any): boolean {
    if (col) {
      // if the subfile has all the data and the column is exportable
      if ((this.props.model.subfileHasAllData === true || this.props.screenDef.gridDefinition.exportable) && col.exportable !== false) {
        return true;
      }
    }

    return false;
  }


  actionMenuBodyTemplate(ev: any): React.ReactElement {
    const rowIndex = ev.index;
    return (
      <React.Fragment>
        <Button
          icon="pi pi-cog"
          className="p-button-rounded p-button-text grid-menu-btn"
          onClick={(event: any): void => {
            if (!this.isMenuOpen) {
              if (!this.isInSelection(rowIndex)) {
                this.addToSelection(rowIndex);
              }
              this.actionMenu.show(event);
              this.isMenuOpen = true;
            } else {
              if (this.isInSelection(rowIndex)) {
                this.removeFromSelection(rowIndex);
              }
              this.actionMenu.hide(event);
              this.isMenuOpen = false;
            }
          }}
        />
      </React.Fragment>
    );
  }

  /**
   *  Generate the body of the grid (DataTable)
   */
  getGridDataTable(): React.ReactElement {
    return (
      <React.Fragment>
        <Menu
          id="grid-actions-menu"
          model={this.actionItems}
          popup={true}
          ref={(el: any): void => this.actionMenu = el}
          onHide={(): void => { this.isMenuOpen = false; }}
        />
        <DataTable
          autoLayout={true}
          resizableColumns={true}
          columnResizeMode={'fit'}
          totalRecords={this.totalRecords}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink"
          pageLinkSize={1}
          paginator={true}
          rows={this.numRows}
          lazy={true}
          first={this.firstPage}
          value={this.props.model['subfile']}
          header={this.getGridDataTableHeader()}
          selection={this.selectedRows}
          onPage={this.getPage}
          onSelectionChange={(e: any): void => this.onSelectionChange(e.value)}
          rowClassName={this.rowClass}
        >
          {this.selectionMode !== 'none' && (
            <Column className="no-default-select" selectionMode={this.selectionMode} style={{ width: '45px', maxWidth: '45px' }} />
          )}
          {this.props.actionsAsMenu && (
            <Column style={{ minWidth: '45px', maxWidth: '45px', textAlign: 'center' }} body={this.actionMenuBodyTemplate} />
          )}
          {
            this.gridDefinition.columns.map((col: any, index: any): any => {
              if (!this.hiddenColumn(col[0].field)) {
                return <Column key={index} className={`${this.getAddClass(col[0].addClass)}`} style={this.getColumnWidth(col[0])} field={col[0].field} header={this.getColumnHeader(col[0])} columnKey={index.toString()} body={this.cellBodyTemplate} sortable={this.getSortable(col[0])} exportable={this.getExportable(col[0])}/>;
              }
            })
          }
        </DataTable >
      </React.Fragment>
    );
  }

  /**
   * Renders the component
   */
  render(): React.ReactElement {
    return (
      <div className={`f-grid ${this.props.actionsAsMenu ? 'has-actions-menu' : ''} ${this.getAddClass(this.props.screenDef.gridDefinition.addClass)}`} ref={(el: any): any => this.table = el}>
        {/* {this.props.body ? this.props.body : this.getGridDataTable()} */}
        {this.getGridDataTable()}
      </div>
    );
  }
}
