import React from 'react';
import FTextInput from '../../layout/f-text-input/f-text-input';
import FNumericInput from '../../layout/f-numeric-input/f-numeric-input';
import FMultilineInput from '../../layout/f-multiline-input/f-multiline-input';
import FSelectInput from '../../layout/f-select-input/f-select-input';
import FDateTimeInput from '../../layout/f-date-time-input/f-date-time-input';
import FHyperlink from '../../layout/f-hyperlink/f-hyperlink';
import FCustomInput from '../../layout/f-custom-input/f-custom-input';

/**
 * Interface for grid cells
 */
export interface FGridCellProps {
  /**
   * Information about the inputs in the screen defintion
   */
  definition: any;

  /**
   *  The data model
   */
  data: any;

  /**
   * If the component is a dropdown: options displayed when dropdown is opened
   */
  dropdownOptions?: any;

  /**
   *  The fieldName used for backend focus
   */
  fieldName: string;

  /**
   * Index of the row
   */
  row: number;

  /**
   * The selection mode of the grid (single, multiple, action)
   */
  singleSelectionMode: boolean;

  /**
   * maxlength attribute of the input
   */
  maxlength: number;

  /**
   * If the label is displayed or the value only (smaller)
   */
  displayValueOnly?: boolean;

  /**
   * Error message string
   */
  errorMessage: string;

  /**
   *
   */
  condition: object;

  /**
   *
   */
  dateFormat: string;

  /**
   *
   */
  timeSeparator: string;

  /**
   *
   */
  showSeconds: boolean;

  /**
   * When data is changed
   */
  dataChange?: (newValue: any) => void;

  /**
   * When control get the focus
   */
  onFocus?: (fieldName: string, shortFieldName: string, rowNumber: number) => void;

  /**
   * hyperlink to be displayed
   */
  hrefUrl: string;
  /**
   * link regex pattern
   */
   linkRegexp: string;

}

/**
 *
 */
export default class FGridCell extends React.Component<FGridCellProps, any> {
  /**
   *
   */
  constructor(props: FGridCellProps) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * @ignore
   * cell value changed event
   */
  onValueChange(newValue: any): void {
    if (this.props.dataChange) {
      this.props.dataChange(newValue);
    }
  }

  /**
   * on focus event call select method of input or
   * if grid in single selection mode, set the table row to the table selection
   * @param ev trigger event
   */
  onFocus(ev: any): void {
    const element = ev.target;
    if (element.tagName === 'INPUT') {
      element.select();
    }

    // TODO: Put this code in the grid
    /*if (this.props.singleSelectionMode) {
      this.table.selection = this.table.value[this.row];
    }*/

    if (this.props.onFocus) {
      this.props.onFocus(this.props.definition.field, this.props.definition.fieldId, this.props.row);
    }
  }

  /**
   * Returns the options with translated labels
   */
  getDropdownValues(options: any, field: string): any {
    // First if dropdownOptions are in the model for this field
    if (this.props.dropdownOptions && this.props.dropdownOptions[field]) {
      // Clear options (if they are declared in screen definition)
      options = [];

      // Add to options
      this.props.dropdownOptions[field].forEach((option: any, _index: number): void => {
        options.push({ value: option.code, label: option.description });
      });
    }

    return options;
  }

  /**
   * Renders the component
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>
        {
          this.props.definition && this.props.definition.type === 'string' && (
            <FTextInput
              id={this.props.fieldName}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'actionOnRowSelect' && (
            <FTextInput
              id={this.props.fieldName}
              readonly={true}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={true}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'multiline' && (
            <FMultilineInput
              id={this.props.fieldName}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              // label={this.props.definition.labelLocation === 'above' ? this.props.definition.header1 : this.props.definition.label}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'pnumber' && (
            <FNumericInput
              positive={true}
              id={this.props.fieldName}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'number' && (
            <FNumericInput
              id={this.props.fieldName}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}
              displayFormat={this.props.definition.displayFormat}

              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}
              labelOnTop={this.props.definition.labelLocation === 'above'}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'pinteger' && (
            <FNumericInput
              id={this.props.fieldName}
              positive={true}
              decimal={false}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'integer' && (
            <FNumericInput
              id={this.props.fieldName}
              decimal={false}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'dropdown' && (
            <FSelectInput
              id={this.props.fieldName}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              values={this.getDropdownValues(this.props.definition.options, this.props.fieldName)}
              value={this.props.data}
              customValue={this.props.definition.customValue}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && (this.props.definition.type === 'date' || this.props.definition.type === 'time' || this.props.definition.type === 'datetime') && (
            <FDateTimeInput
              id={this.props.fieldName}
              dateFormat={this.props.dateFormat}
              timeSeparator={this.props.timeSeparator}
              showSeconds={this.props.showSeconds}
              type={this.props.definition.type}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}

              error={this.props.errorMessage}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'hyperlink' 
          && this.props.condition && this.props.condition['protect'] &&(
            <FHyperlink
              id={this.props.fieldName}
              readonly={this.props.condition ? this.props.condition['readonly'] : false}
              value={this.props.data}
              maxlength={this.props.definition.maxlength}
              required={this.props.definition.required}

              displayFormat={this.props.definition.displayFormat}
              promptable={this.props.definition.lookupFlag}
              suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

              onValueChange={(newValue: any): void => { this.onValueChange(newValue); } }
              onFocus={(e: any): void => this.onFocus(e)}

              color={this.props.condition ? this.props.condition['color'] : ''}
              highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
              protect={this.props.condition ? this.props.condition['protect'] : ''}
              reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
              underline={this.props.condition ? this.props.condition['underline'] : ''}
              hidden={this.props.condition ? this.props.condition['hidden'] : ''}
              error={this.props.errorMessage}
              name={''} 
              hrefUrl={this.props.hrefUrl}
              linkRegexp={this.props.linkRegexp}
            />
          )
        }
        {
          this.props.definition && this.props.definition.type === 'hyperlink' 
          && this.props.condition && !this.props.condition['protect'] &&(
            <FTextInput
            id={this.props.fieldName}
            readonly={this.props.condition ? this.props.condition['readonly'] : false}
            value={this.props.data}
            maxlength={this.props.definition.maxlength}
            required={this.props.definition.required}

            displayFormat={this.props.definition.displayFormat}
            promptable={this.props.definition.lookupFlag}
            suffix={this.props.definition.options ? this.props.definition.options.suffix : null}

            onValueChange={(newValue: any): void => { this.onValueChange(newValue); }}
            onFocus={(e: any): void => this.onFocus(e)}

            color={this.props.condition ? this.props.condition['color'] : ''}
            highintensity={this.props.condition ? this.props.condition['highintensity'] : ''}
            protect={this.props.condition ? this.props.condition['protect'] : ''}
            reverseimage={this.props.condition ? this.props.condition['reverseimage'] : ''}
            underline={this.props.condition ? this.props.condition['underline'] : ''}
            hidden={this.props.condition ? this.props.condition['hidden'] : ''}
            error={this.props.errorMessage}
          />
          )
        }

      </React.Fragment>
    );
  }
}
